import React from 'react';
import DynamicList from "../shared/dynamic_list";
import { bindMethods, dig } from "../../shared/functions";
import FormGroup from "../forms/form_group";
import ProfileScope from "../profiles/profile_scope";
import { allSystemExtensionPolicies as ajaxClients } from '../../ajax_clients/profile_data/system_extension_policies'
import { errorHandler, getAjaxClient, successHandler } from "../profiles/profile_utilities";

export default class SystemExtensionPolicies extends React.Component {

    constructor(props) {
        super(props);
        this.state      = {
            name:                       props.data.name || "",
            description:                props.data.description || "",
            allowUserOverrides:         props.data.allowUserOverrides || false,
            systemExtensionIdentifiers: props.data.systemExtensionIdentifiers || [],
            reinstallAfterOSUpdate:     props.data.reinstallAfterOSUpdate,
            minimumMacosVersion:        props.data.minimumMacosVersion,
            maximumMacosVersion:        props.data.maximumMacosVersion,
            allowedMacosArchitecture:   props.data.allowedMacosArchitecture,
            isDeviceProfile:            props.data.isDeviceProfile,
            allowedPlatforms:           props.data.allowedPlatforms,
            saving:                     false,
            errors:                     {}
        };
        this.headers    = [
            { displayName: 'Team Identifier', key: 'teamIdentifier' },
            { displayName: 'Bundle Identifier', key: 'bundleIdentifiers' },
            {
                displayName: 'Allowed Extension Types',
                key:         'allowedSystemExtensionTypes',
                multiSelect: true,
                options:     [
                    { displayName: 'Driver', value: 'DriverExtension' },
                    { displayName: 'Network', value: 'NetworkExtension' },
                    { displayName: 'Endpoint Security', value: 'EndpointSecurityExtension' },
                ]
            },
            { displayName: 'Notes', key: 'notes' },
            {
                displayName: 'Removable',
                key: 'removable',
                boolean: true,
                tooltip: "Allow system extension to remove itself without admin approval. A bundle identifier must be provided in order to make the extension removable.",
                optionDisplayName: ""
            },
            {
                displayName: 'Prevent UI Removal',
                key: 'nonRemovableFromUi',
                boolean: true,
                tooltip: "Prevents removal of extension via the System Settings UI when SIP is enabled.  A bundle identifier must be provided in order to prevent UI removal.",
                optionDisplayName: ""
            },
        ];
        this.ajaxClient = getAjaxClient(props.assignableObject, ajaxClients)
        bindMethods(this);
    }

    onChangeItem(updatedItem, updatedIndex) {
        let bundleIdentifiers    = updatedItem.bundleIdentifiers.split(",").map(string => string.trim());
        const existingIdentifier = this.state.systemExtensionIdentifiers[updatedIndex];
        if (existingIdentifier && bundleIdentifiers.length === existingIdentifier.bundleIdentifiers.length) {
            bundleIdentifiers = bundleIdentifiers.filter((string) => string.length > 0)
        }
        updatedItem = {
            ...updatedItem,
            bundleIdentifiers: bundleIdentifiers
        }
        this.setState({
            systemExtensionIdentifiers: this.state.systemExtensionIdentifiers.map((row, index) => index === updatedIndex ? updatedItem : row)
        })
    }

    onRemoveItem(removedItem, removedIndex) {
        this.setState({
            systemExtensionIdentifiers: this.state.systemExtensionIdentifiers.filter((item, index) => index !== removedIndex)
        })
    }

    onAddItem() {
        const newItem = {
            teamIdentifier:              "",
            bundleIdentifiers:           [],
            allowedSystemExtensionTypes: ['DriverExtension', 'NetworkExtension', 'EndpointSecurityExtension'],
        };

        this.setState({
            systemExtensionIdentifiers: [...this.state.systemExtensionIdentifiers, newItem]
        })
    }

    setName(e) {
        this.setState({ name: e.target.value })
    }

    setDescription(e) {
        this.setState({ description: e.target.value })
    }

    onChangeAllowUserOverrides(e) {
        this.setState({ allowUserOverrides: e.target.checked })
    }

    onChangeIsDeviceProfile(e) {
        this.setState({ isDeviceProfile: e.target.checked })
    }

    onChangeReinstallAfterOSUpdate(e) {
        this.setState({ reinstallAfterOSUpdate: e.target.checked })
    }

    renderData() {
        return this.state.systemExtensionIdentifiers.map((identifier, index) => {
            let errors;
            if (this.state.errors && this.state.errors.systemExtensionIdentifiers) {
                errors = this.state.errors.systemExtensionIdentifiers[index]
            }
            return {
                ...identifier,
                bundleIdentifiers: identifier.bundleIdentifiers.join(", "),
                notes:             identifier.notes || "",
                errors
            }
        })
    }

    saveData(e) {
        e.preventDefault();

        this.setState({ saving: true }, () => {
            const {
                      name, description, isDeviceProfile, allowUserOverrides, systemExtensionIdentifiers, allowedMacosArchitecture, reinstallAfterOSUpdate
                  }      = this.state;
            const { id } = this.props.data
            const allowedPlatforms = this.state.allowedPlatforms.map((os) => os.displayName)
            const minimumMacosVersion = this.state.minimumMacosVersion.version;
            const maximumMacosVersion = this.state.maximumMacosVersion.version;

            let saveData = {
                id, name, description, isDeviceProfile, allowUserOverrides, systemExtensionIdentifiers,
                allowedPlatforms, minimumMacosVersion, maximumMacosVersion, allowedMacosArchitecture, reinstallAfterOSUpdate
            }

            if (id) {
                this.ajaxClient.update(id, saveData).then(successHandler(this)).catch(errorHandler(this));
            } else {
                this.ajaxClient.create(saveData).then(successHandler(this)).catch(errorHandler(this));
            }

        })
    }

    selectMinimumOSVersion(minimumMacosVersion) {
        this.setState({ minimumMacosVersion })
    }

    selectMaximumOSVersion(maximumMacosVersion) {
        this.setState({ maximumMacosVersion })
    }

    selectAllowedMacosArchitecture(allowedMacosArchitecture) {
        this.setState({ allowedMacosArchitecture })
    }

    selectAllowedOs(osName) {
        const oses = this.state.allowedPlatforms

        if (oses.find(os => os.name === osName)) {
            this.setState({ allowedPlatforms: oses.filter(os => os.name !== osName) })
        } else {
            oses.push(this.props.supportedPlatformOptions.find(platform => platform.name === osName))
            this.setState({ allowedPlatforms: oses })
        }
    }

    render() {
        const disabled = this.props.data.id ? !this.props.policy.update : !this.props.policy.create
        let buttons;
        if (disabled) {
            buttons = <div className="buttons">
                <a className="btn btn-default" href={ AdminRoutes.adminProfilesPath({ format: null }) }>Back</a>
            </div>
        } else {
            buttons = <div className="buttons">
                <a className="btn btn-default" href={ AdminRoutes.adminProfilesPath({ format: null }) }>Cancel</a>
                <input type="submit" value="Save" disabled={ this.state.saving } className="btn btn-primary"
                       onClick={ this.saveData }/>
            </div>
        }
        return <div className="form form-horizontal">
            <h3>General</h3>
            <FormGroup disabled={ disabled } title="Name" error={ this.state.errors.name } required={ true }>
                <input value={ this.state.name } onChange={ this.setName }/>
            </FormGroup>

            <FormGroup disabled={ disabled }
                title={ "Description" }
                error={ dig(['state', 'errors', 'description'], this) }
                hint="Optional description presented to the end user."
            >
                <input name="description" value={ this.state.description } onChange={ this.setDescription }/>
            </FormGroup>

            <FormGroup disabled={ disabled } inputClasses={ ["checkbox"] }>
                <div>
                    <label className="boolean optional">
                        <input
                            disabled={ disabled }
                            className="boolean optional"
                            type="checkbox"
                            name="is_device_profile"
                            checked={ this.state.isDeviceProfile }
                            onChange={ this.onChangeIsDeviceProfile }
                        />
                        For macOS devices, deploy as a device profile instead of a user profile.
                    </label>
                </div>
            </FormGroup>
            <FormGroup disabled={ disabled } inputClasses={ ["checkbox"] }>
                <div>
                    <label className="boolean optional">
                        <input
                            disabled={ disabled }
                            className="boolean optional"
                            type="checkbox"
                            name="reinstall_after_os_update"
                            checked={ this.state.reinstallAfterOSUpdate }
                            onChange={ this.onChangeReinstallAfterOSUpdate }
                        />
                        Re-install profile automatically after macOS software updates are detected.
                    </label>
                </div>
            </FormGroup>

            <FormGroup disabled={ disabled } inputClasses={ ["checkbox"] }>
                <div>
                    <label className="boolean optional">
                        <input
                            disabled={ disabled }
                            className="boolean optional"
                            type="checkbox"
                            name="allow_user_overrides"
                            checked={ this.state.allowUserOverrides }
                            onChange={ this.onChangeAllowUserOverrides }
                        />
                        Allow user to approve system extensions that are not specified below
                    </label>
                </div>
            </FormGroup>

            <h3>System Extension list</h3>

            <p>System extensions may be allowed by specifying a team identifier and zero or more bundle identifiers.
                Multiple bundle identifiers may be specified as a comma separated list. At least one extension type
                must be selected for each identifier.</p>

            <p>Specifying system extensions that can be removed is available for macOS 12 and later.</p>

            <DynamicList
                policy={ this.props.policy }
                fieldName={ 'data[system_extension_identifiers]' }
                headers={ this.headers }
                rows={ this.renderData() }
                onChangeItem={ this.onChangeItem }
                onRemoveItem={ this.onRemoveItem }
                onAddItem={ this.onAddItem }
            />

            <ProfileScope
                policy={ this.props.policy }
                selectMinVersion={ this.selectMinimumOSVersion }
                selectMaxVersion={ this.selectMaximumOSVersion }
                selectAllowedMacosArchitecture={ this.selectAllowedMacosArchitecture }
                selectAllowedOs={ this.selectAllowedOs }

                minVersion={ this.state.minimumMacosVersion }
                maxVersion={ this.state.maximumMacosVersion }
                allowedArchitecture={ this.state.allowedMacosArchitecture }

                minimumMacosVersions={ this.props.minimumMacosVersions }
                maximumMacosVersions={ this.props.maximumMacosVersions }
                allowedMacosArchitectures={ this.props.allowedMacosArchitectures }

                allowedPlatforms={ this.state.allowedPlatforms }
                supportedPlatformOptions={ this.props.supportedPlatformOptions }
                supportedPlatforms={ this.props.supportedPlatforms }

                errors={ this.state.errors }
            />

            { buttons }

        </div>
    }
}